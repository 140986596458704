import { lazy, Suspense } from "react";
// const App = lazy(() => import('./App'));//懒加载方式
import App from '../App'//正常加载方式

export default [
    {
        path: "/",
        element: <App />,//如果是懒加载就必须这样写Suspense 里的fallback会在还没加载出组件时显示--<Suspense fallback={<div>loading</div>}><App /></Suspense>
        // errorElement: <ErrorPage />,//当APP页面出现错误时（比如null.slice()会导致页面崩溃）此时会加载这个页面。
        children: [

        ]
    },
]